<template>
  <div v-if="isShow">
    <div class="dialog-box" :class="[isShow ? 'dialog-box-show' : '', isAnimation ? 'dialog-box-hide' : '']">
      <div class="dialog" :class="[isShow ? 'dialog-show' : '', isAnimation ? 'dialog-hide' : '']">
        <div class="dialog-cont" :style="`width: ${width};`">
          <div class="dialog-top">
            <span>{{title}}</span>
          </div>
          <div class="dialog-center">
            <slot></slot>
          </div>
          <div class="dialog-shut" @click="dialogSwitch()"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  data () {
    return {
      isShow: false,
      isAnimation: false
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '标题'
    }
  },
  watch: {
    '$props.value': function (n, o) {
      if (n) {
        this.dialogShow()
      } else {
        this.dialogHide()
      }
    }
  },
  created () {
    const w = document.documentElement.clientWidth || document.body.clientWidth;
    const h = document.documentElement.clientHeight || document.body.clientHeight;
    this.appTyple = `height: ${(w / 8) * 3}px;`;
    if (this.$props.value) {
      this.dialogShow()
    }
  },
  mounted () {},
  methods: {
    // 弹窗显示
    dialogShow () {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
      document.body.style.overflow = 'hidden'
      this.isShow = true
    },
    // 弹窗隐藏
    dialogHide () {
      const that = this
      document.body.style.removeProperty('overflow')
      document.getElementsByTagName('body')[0].style.removeProperty('overflow')
      that.isAnimation = true
      setTimeout(() => {
        that.isAnimation = false
        that.isShow = false
      }, 130)
    },
    dialogSwitch () {
      this.$emit('input', false)
      this.$emit('shut', false)
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-box {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.dialog-box-show {
  animation: show-animation 0.3s ease-out;
}
@keyframes show-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.dialog-box-hide {
  animation: hide-animation 0.15s ease-in;
}
@keyframes hide-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.dialog{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  // overflow-y: auto;
  // padding: 150px 0;
  padding-top: .8rem;
}
.dialog-cont {
  width: 54%;
  height: calc(100% - .8rem);
  margin: auto;
  background-color: #ffffff;
  border-radius: 6px 6px 0 0;
  position: relative;
  overflow: hidden;
}
.dialog-show {
  animation: box-animation-show 0.3s ease-out;
}
@keyframes box-animation-show {
  0% {
    /* transform: scale(1.5); */
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    /* transform: scale(1); */
    transform: translateY(0px);
    opacity: 1;
  }
}
.dialog-hide {
  animation: box-animation-hide 0.15s ease-in;
}
@keyframes box-animation-hide {
  0% {
    /* transform: scale(1); */
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    /* transform: scale(1.3); */
    transform: translateY(-100px);

    opacity: 0;
  }
}
.dialog-top {
  padding-left: 18px;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  // color: #ffffff;
  color: #00deff;
  font-weight: bold;
  background-color: #116592;
  // background-image: linear-gradient(to right, #0064ff, #009dff);
}
.dialog-center {
  padding: 15px;
  position: absolute;
  top: 42px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(41, 40, 40);
}
.dialog-shut {
  cursor: pointer;
  width: 32px;
  height: 32px;
  transform: rotate(45deg);
  position: absolute;
  top: 5px;
  right: 10px;
}
.dialog-shut::before{
  content: '';
  height: 2px;
  width: 20px;
  border-radius: 2px;
  background-color: #ffffff;
  position: absolute;
  top: 15px;
  left: 6px;
}
.dialog-shut::after{
  content: '';
  height: 20px;
  width: 2px;
  border-radius: 2px;
  background-color: #ffffff;
  position: absolute;
  top: 6px;
  left: 15px;
}
.image-box{
  position: relative;
}
</style>
    