



















import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  public appTyple: string = '';
  public isLoading: boolean = false;
  public isLoadingHeid: boolean = false;

  @Watch('$store.state.loading')
  private onChange(): void {
    if (this.$store.getters.getLoading) {
      this.isLoading = true;
    } else {
      this.isLoadingHeid = true;
      setTimeout(() => {
        this.isLoading = false;
        this.isLoadingHeid = false;
      }, 300);
    }
  }
  private created(): void {
    const w: number = document.documentElement.clientWidth || document.body.clientWidth;
    this.appTyple = `width:${w * 1.2}px;height: calc(100vh);`;
    document.documentElement.style.fontSize = w / 20 + 'px';
    if (this.$route.query.str) {
      const str = this.$route.query.str
      window.sessionStorage.setItem('token', str as 'token');
    }
  }
}
