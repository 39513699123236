
































































import echarts from 'echarts';
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({
  components: {},
})
export default class EchartsB extends Vue {
  @Prop({ type: String }) private readonly id!: string;
  @Prop({ type: String, default: '标题' }) private readonly title!: string;
  @Prop() private readonly textArr!: any;
  @Prop() private readonly dataArr!: any;
  @Prop() private readonly contrast!: boolean;
  @Prop() private readonly blockColor!: string;
  @Prop() private readonly classType!: string; // 区分字段
  @Prop() private readonly listColor!: string[];
  @Prop(Number) public readonly type!: Number;
  public colorArr: string[] = ['#EA3191', '#FF9D4D', '#FAF321', '#47FF64', '#5B8FF9', '#62D9FF', '#B588FF'];
  public blockColorStyle1: string = '';
  public blockColorStyle2: string = '';
  public created() {
    this.blockColorStyle1 = `background-color: ${this.blockColor};`
    this.blockColorStyle2 = `border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) ${this.blockColor};`
  }
}
