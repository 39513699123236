

















import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class Daily extends Vue {
  @Prop({ type: String, default: '标题' }) private readonly title!: string;
  @Prop() private readonly blockColor!: String;
  @Prop() private readonly list!: any;
  public blockColorStyle1: string = '';
  public blockColorStyle2: string = '';
  private created() {
    this.blockColorStyle1 = `background-color: ${this.blockColor};`
    this.blockColorStyle2 = `border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) ${this.blockColor};`
  }
}
