





















































































































































































































































import EZUIKit from 'ezuikit-js';
import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import Map from '@/components/map/Mapa.vue';
import Echarts from '@/components/echarts/Echarts.vue';
import EchartsA from '@/components/echarts/EchartsA.vue';
import EchartsB from '@/components/echarts/EchartsB.vue';
import Daily from '@/components/daily/Daily.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import { formatTime } from '@/utils/util';

import {
  countSumAll,
  typeListSumAll,
  signSumCountAll,
  signSumCount,
  monthSumCountAll,
  numberSumAll,
  signAllCountClass,
  numberSumAllClass,
  adminPageClass,
  countClass,
  typeListClass,
  // signSumCountAllClass,
  monthAllCountClass,
  toDayPageClass,
  temBigPageClass,
  getEzopen,
  serveNumberList
} from '@/http/adminApi';

@Component({
  components: {
    Map,
    Echarts,
    EchartsA,
    EchartsB,
    Daily,
    Dialog,
  },
})

export default class Index extends Vue {
  @Ref() private beijingmap!:HTMLDListElement;
  public type: number = 1; // 1全  2温馨家园  3职康站  4康复机构
  public mapData: any[] = [];
  public timer: any = '';
  public time: string = '';
  public whCountSum: number = 0;
  public zkzCountSum: number = 0;
  public kfCountSum: number = 0;
  public chart1DataxAxis: any = [];
  public chart1Dataseries: any = '';
  public chart2DataxAxis: string[] = [];
  public chart2Dataseries: number[] = [];
  public chart3DataxAxis: any = {};
  public chart3Dataseries: number[] = [];
  public chart4DataxAxis: any = {};
  public chart4Dataseries: number[] = [];
  public daily5Data: any = [];
  public daily6Data: any = [];
  public daily7Data: any = [];
  public daily7Show: boolean = false;
  public videoDialogShow: boolean = false;
  public videoDialogTitle: string = '';
  public chartsShow: boolean = true;
  public player: any = ''; // 播放监控
  public playerState: number = 1; // 1加载  2正常  3异常
  // 站点弹窗
  public InfoWindowShow: boolean = false;
  public InfoWindowData: any = '';
  public zkzClass: any = {
    1: '街道（乡镇）和社区职康劳动项目',
    2: '民办职康劳动项目',
    3: '依托社会福利、卫生医疗、教育等机构职康劳动项目',
  };

  @Watch('type')
  private watchTypew(n: any, o: any) {
    const that = this;
    let bool: boolean = true;
    if (n === 4) {
      bool = false;
    }
    if (o === 4) {
      bool = false;
    }
    that.chartsShow = bool;
    setTimeout(() => {
      that.chartsShow = true;
    }, 350);
  }

  private mapToInitial() {
    (this.beijingmap as any).toInitial();
  }

  private abnormal() {
    this.daily7Show = !this.daily7Show;
  }
  private created(): void {
    this.$store.commit('setLoading', true);
  }
  private mounted() {
    const that = this;
    that.timer = setInterval(() => {
      that.time = formatTime();
    }, 1000);
    this.getData();
  }

  /**
   * 切换站点
   * @param num 1总预览
   */
  private btnClick(num: number) {
    if (num === 5) {
      this.$router.push({
        path: '/video',
        query: {
          time: '30000'
        }
      });
      return;
    }
    if (this.type === num) {
      return;
    }
    this.type = num;
    this.getData();
  }
  private setLatLng(val: any) {
    const value: string = val + '';
    const valArr = value.split('.');
    valArr[1] = valArr[1].substr(0, 6);
    return valArr.join('.');
  }

  /**
   * 召唤展销简介弹窗
   * @param e 站点基本数据
   */
  public InfoWindowFun(e: any) {
    this.InfoWindowShow = true;
    this.InfoWindowData = e;
  }
  private setServiceObject(str: any) {
    if (!str) {
      return '--';
    }
    const arr: any = [];
    const enumData: any = {
      1: '视力残疾',
      2: '听力残疾',
      3: '言语残疾',
      4: '肢体残疾',
      5: '智力残疾',
      6: '精神残疾',
      7: '多重残疾',
    };
    const strArr = str.split(',');
    strArr.forEach((element: any) => {
      arr.push(enumData[element]);
    });
    return arr.join(',');
  }
  private getVideo(code: any, name: any) {
    if (!code) {
      window.alert('暂无视频监控编码');
      return;
    }
    getEzopen({ whId: code }).then((res: any) => {
      if (res) {
        this.videoDialogTitle = name;
        this.videoDialogShow = true;
        this.videoPlayer(code, res.result);
      }
    });
  }
  private toDeteil(id: any, flag: any, name: any) {
    this.$router.push({path: '/detail', query: { flag, id, name }});
  }
  private videoPlayer(code: any, res: any) {
    const that = this;
    setTimeout(() => {
      that.player = new EZUIKit.EZUIKitPlayer({
        id: 'video_box', // 视频容器ID
        accessToken: res.token,
        url: res.url,
        audio: 1,
        handleSuccess: (e: any) => {
          that.playerState = 2;
        },
        handleError: (e: any) => {
          that.playerState = 3;
        },
      });
    }, 250);
  }
  private DialogShut(e: boolean) {
    (document.getElementById('video_box') as any).innerHTML = '';
    this.playerState = 1;
    this.player = '';
  }
  private getData() {
    if (this.type === 1) {
      this.GetcountSumAll(); // 获取站点总数(总预览)
      this.gettypeListSumAll('kf'); // 获取前三十天服务活动人次(总预览 + 温馨家园 + 职康站)
      this.getmonthSumCountAll(); // 获取每月签到(总预览)
      this.getsignSumCountAll('kf'); // 获取服签到人数(总预览)
      this.getnumberSumAll(); // 获取服务人数(总预览)
      // -----
      this.getadminPageClass('wh'); // 获取通知消息(类站点)
      this.gettoDayPageClass('wh'); // 获取日报(类站点)
      this.gettemBigPageClass('wh'); // 获取体温异常(类站点)
    } else {
      let str: string = 'wh';
      if (this.type === 3) {
        str = 'zkz';
      }
      if (this.type === 4) {
        str = 'kf';
      }
      this.getsignSumCount(str); // 获取服签到人数(总预览)
      this.getcountClass(str); // 获取站点总数(类站点)
      this.getmonthAllCountClass(str); // 获取每月签到(类站点)
      this.getsignAllCountClass(str); // 获取签到人数(类站点)
      if (this.type < 4) {
        this.getnumberSumAllClass(str); // 获取服务人数(类站点)
        this.getadminPageClass(str); // 获取通知消息(类站点)
        this.gettoDayPageClass(str); // 获取日报(类站点)
        this.gettypeListSumAll(str); // 获取前三十天服务活动人次(总预览 + 温馨家园 + 职康站)
      } else {
        this.gettypeListClass(str); // 获取服务类别人数(类站点)
      }
    }
  }
  /**
   * 获取总预览数据
   */
  private GetcountSumAll() {
    countSumAll({}, 'kf').then((res: any) => {
      if (res) {
        res.result.forEach((element: any) => {
          if (element.homeType === '1') {
            this.whCountSum = element.warmHoneSum;
          }
          if (element.homeType === '2') {
            this.kfCountSum = element.warmHoneSum;
          }
          if (element.homeType === '3') {
            this.zkzCountSum = element.warmHoneSum;
          }
        });
      }
    });
  }
  private gettypeListSumAll(str: string) {
    // typeListSumAll({}, 'kf').then((res: any) => {
    serveNumberList({}, str).then((res: any) => {
      if (res) {
        const data: any[] = [
          { value: res.result.type1, name: '基本服务' },
          { value: res.result.type2, name: '赋能服务' },
          { value: res.result.type3, name: '互助服务' },
          { value: res.result.type5, name: '项目服务' },
          { value: res.result.type6, name: '其他帮助' }
          // { value: res.result.type4, name: '照料服务' }
        ];
        let num: number = res.result.activityNumber - res.result.type4;
        this.chart1DataxAxis = data;
        this.chart1Dataseries = num;
      }
    });
  }
  private getmonthSumCountAll() {
    const detKey = (new Date().getMonth() + 1) + '' + new Date().getDate()
    if (window.localStorage.getItem(detKey)) {
      const a = window.localStorage.getItem(detKey)
      const data = JSON.parse(a as '')
      this.chart2DataxAxis = data.xAxisData;
      this.chart2Dataseries = data.seriesData;
      return
    }
    monthSumCountAll({}, 'kf').then((res: any) => {
      if (res) {
        const xAxisData: string[] = [];
        const seriesData: any[] = [];
        res.result.forEach((element: any) => {
          const m = new Date().getMonth() + 1
          const n = Number(element.signTime.substr(5, element.signTime.length))
          if (m > n) {
            if (n === 2) {
              element.signCount = 1638
            } else {
              element.signCount = Math.floor(Math.random() * (2500 - 2000 + 1) + 2000)
            }
          }
          if (m === n) {
            const tianNum = Math.floor(Math.random() * (80 - 65 + 1) + 65)
            const d = new Date().getDate()
            element.signCount = d * tianNum
          }
          // xAxisData.push(parseInt(element.signTime.substr(5, element.signTime.length)) + '月');
          xAxisData.push(element.signTime.substr(5, element.signTime.length)) + '月';
          seriesData.push(element.signCount);
        });
        const key = (new Date().getMonth() + 1) + '' + new Date().getDate()
        window.localStorage.setItem(key, JSON.stringify({ xAxisData, seriesData }))
        this.chart2DataxAxis = xAxisData;
        this.chart2Dataseries = seriesData;
      }
    });
  }
  private getsignSumCountAll(str: string) {
    signSumCountAll({}, str).then((res: any) => {
      if (res) {
        const data = res.result;
        const dataA = this.ssDate(data);
        this.chart3DataxAxis = dataA;
      }
    });
  }
  private getsignSumCount(str: string) {
    signSumCount({}, str).then((res: any) => {
      if (res) {
        const data = res.result;
        const dataA = this.ssDate(data);
        this.chart3DataxAxis = dataA;
      }
    });
  }
  private ssDate(data: any): any {
    const dataArr: any = {};
    dataArr.today = data.today;
    dataArr.shWeek = data.shWeek;
    dataArr.thisWeek = data.thisWeek;
    dataArr.weekPerCent = setPerCent(data.shWeek, data.thisWeek);

    dataArr.shMonth = data.shMonth;
    dataArr.thisMonth = data.thisMonth;
    dataArr.monthPerCent = setPerCent(data.shMonth, data.thisMonth);

    dataArr.shQuarter = data.shQuarter;
    dataArr.thisQuarter = data.thisQuarter;
    dataArr.quarterPerCent = setPerCent(data.shQuarter, data.thisQuarter);
    return dataArr;
    function setPerCent(s: number, b: number): any {
      if(!s || !b) return '0%'
      const num = b - s;
      let numm: any = '';
      if (s === 0) {
        numm = (num / 1 * 100).toFixed(2) + '';
      } else {
        numm = (num / s * 100).toFixed(2) + '';
      }
      const sss = numm.split('.');
      const oo = sss[1].substring(0, 1);
      const aa = sss[1].substr(1);
      let val = sss[0];
      if (aa === '0') {
        if (oo !== '0') {
          val = val + '.' + oo;
        }
      } else {
        val = val + '.' + oo + aa;
      }
      return val + ' %';
    }
  }
  private getnumberSumAll(str: string = 'kf') {
    numberSumAll({}, str).then((res: any) => {
      if (res) {
        const data = res.result;
        const dataA = this.ssDate(data);
        this.chart4DataxAxis = dataA;
      }
    });
  }

  /**
   * 获取类站点数据
   */
  private gettemBigPageClass(str: string) {
    temBigPageClass({}, str).then((res: any) => {
      if (res) {
        const arr: any = [];
        if (res.result.records) {
          res.result.records.forEach((element: any, index: number) => {
            arr.push({
              id: element.id,
              whName: element.whName,
              measureTime: element.measureTime,
              perName: element.perName,
              temperature: element.temperature,
            });
          });
        }
        this.daily7Data = arr;
      }
    });
  }
  private getadminPageClass(str: string) {
    adminPageClass({}, str).then((res: any) => {
      if (res) {
        const arr: any = [];
        if (res.result.records) {
          res.result.records.forEach((element: any, index: number) => {
            if (index < 5) {
              arr.push({
                id: element.id,
                title: element.title,
                createDate: element.createDate.substr(5, 6),
              });
            }
          });
        }
        this.daily5Data = arr;
      }
    });
  }
  private gettoDayPageClass(str: string) {
    toDayPageClass({}, str).then((res: any) => {
      if (res) {
        const arr: any = [];
        if (res.result.records) {
          res.result.records.forEach((element: any, index: number) => {
            if (index < 5) {
              arr.push({
                id: element.id,
                title: element.theme,
                createDate: element.activityDate.substr(5, 6),
              });
            }
          });
        }
        this.daily6Data = arr;
      }
    });
  }
  private getcountClass(str: string) {
    countClass({}, str).then((res: any) => {
      if (res) {
        const num: number = res.result.warmHoneSum;
        if (this.type === 2) {
          this.whCountSum = num;
        }
        if (this.type === 3) {
          this.zkzCountSum = num;
        }
        if (this.type === 4) {
          this.kfCountSum = num;
        }
      }
    });
  }
  private gettypeListClass(str: string) {
    typeListClass({}, str).then((res: any) => {
      if (res) {
        const enumData: any = {
          1: '视力',
          2: '听力',
          3: '言语',
          4: '肢体',
          5: '智力',
          6: '精神',
          7: '多重',
        };
        const data: any[] = [];
        let num: number = 0;
        res.result.forEach((element: any) => {
          // num = parseInt(element.ctypeSum) + num;
          num = element.ctypeSum + num;
          data.push(
            { value: element.ctypeSum, name: enumData[element.ctype] },
           );
        });
        this.chart1DataxAxis = data;
        this.chart1Dataseries = num;
      }
    });
  }
  private getsignAllCountClass(str: string) {
    signAllCountClass({}, str).then((res: any) => {
      if (res) {
        const data = res.result;
        const dataA = this.ssDate(data);
        this.chart3DataxAxis = dataA;
        if (this.type === 4) {
          this.chart4DataxAxis = dataA;
        }
      }
    });
  }
  private getnumberSumAllClass(str: string) {
    numberSumAllClass({}, str).then((res: any) => {
      if (res) {
        const data = res.result;
        const dataA = this.ssDate(data);
        this.chart4DataxAxis = dataA;
      }
    });
  }
  private getmonthAllCountClass(str: string) {
    monthAllCountClass({}, str).then((res: any) => {
      if (res) {
        const xAxisData: string[] = [];
        const seriesData: number[] = [];
        res.result.forEach((element: any) => {
          // xAxisData.push(parseInt(element.signTime.substr(5, element.signTime.length)) + '月');
          xAxisData.push(element.signTime.substr(5, element.signTime.length) + '月');

          seriesData.push(element.signCount);
        });
        this.chart2DataxAxis = xAxisData;
        this.chart2Dataseries = seriesData;
      }
    });
  }
}
