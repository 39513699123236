/**
 *  获取当前时间
 */
export const formatTime = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = formatNumber(date.getMonth() + 1);
  const day = formatNumber(date.getDate());
  const hour = formatNumber(date.getHours());
  const minute = formatNumber(date.getMinutes());
  const second = formatNumber(date.getSeconds());
  return year + '年' + month + '月' + day + '日 ' + [hour, minute, second].join(':');
  function formatNumber(n: any) {
    n = n.toString();
    return n[1] ? n : '0' + n;
  }
};
