import axios from 'axios';
// import Vue from 'vue';
import router from '../router';

// 请求失败错误信息提示
function checkCode(message: string) {
  // Vue.prototype.$msg(message, 'error')
  // console.log(message);
}
// function getBaseUrl() {
//   if (process.env.NODE_ENV === 'production') {
//     console.log(window.location.hostname)

//     return window.location.hostname
//   } else {
//     return process.env.VUE_APP_BASE_URL
//   }
// }
// `withCredentails`选项表明了是否是跨域请求
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// axios.defaults.baseURL = getBaseUrl();


// 添加请求拦截器
axios.interceptors.request.use(
  (config: any) => {
    const url: string | undefined = config.url;
    // 响应时间
    if (url === '/large/i/admin/base/permit/oneClickSubmit') {
      axios.defaults.timeout = undefined;
    } else {
      axios.defaults.timeout = 10000;
    }
    // 请求type
    // const listUrl: any = ['/system/login'];
    // if (listUrl.findIndex((item: string) => item === url ) != -1) {
    //   config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    // } else {
    //   config.headers['Content-Type'] = 'application/json; charset=UTF-8';
    // }
    config.headers['Content-Type'] = 'application/json; charset=UTF-8';
    config.headers['Access-Control-Allow-Origin'] = '*';
    // 设置token
    if (window.sessionStorage.getItem('token')) {
      config.headers.Authorization = window.sessionStorage.getItem('token');
    }
    // config.headers.Authorization = 'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIxOTgzMjE4MjkwNiIsInVzZXJfbmFtZSI6IjE5ODMyMTgyOTA2Iiwib3BlbklkIjoiYzczZjU1ZmUyMTI1NDU4MTkzNTYyODkzMjE0MDQ5MGUiLCJ1c2VySWQiOjUyNDIwMTg2NTU4NjI1ODM1MzgsImF1dGhvcml0aWVzIjpbInN5czpsYWdlIiwiUk9MRV9jbF9hZG1pbiIsIlJPTEVfdXNlciIsInN5czp1c2VyIiwiYmFja19tYW5hZ2VyIl0sImV4cCI6MTcxMzk2NzIyMX0.FehBpvwIeO7cg0UoemJvybkJZBwOCv1CVWHur9Y5GuV1NuRwSyy6Ikk-9Bt1VcqxfkqPFq8zAr0HfnBH2y4TBCMMJ21C8RZX752gPfW5dhjQJnF0nKRhFXSXJoqvM49JieNMDcgdrt4Z5TpPSlE2Xc5-5c3MoWIhbxXIkzC98Ms'
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
),

// 添加返回拦截器
axios.interceptors.response.use(
  (response: any) => {
    const { data } = response || {};
    const code: any = data.code + '';
    const url: string = response.config.url;
    const listCode: any = ['000', '1'];
    if (typeof response !== 'undefined') {
      if (listCode.includes(code)) {
        // OK
        return data;
      } else {
        // 不是000
        // 文件下载
        const listUrl = [
          '/system/w/downloadByPath',
          '/large/i/a/lphsHotRoute/exEntity',
          '/large/i/admin/base/exEntity',
        ];
        if (listUrl.includes(url)) {
          return response;
        } else {
          return checkCode(data.msg);
        }
      }
    }
  },
  (error: any) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '请求错误';
          break;
        case 401:
          // 清空缓存，跳到登录界面
          sessionStorage.clear();
          if (error.response.data.msg) {
            // 账号或密码错误
            error.message = error.response.data.msg;
          } else {
            // console.log(121212)
            window.location.href = 'https://cycl.okntec.cn/cl'
            // error.message = '登录过期，请重新登录';
            // if (window.location.pathname !== '/') {
            //   router.push({ path: '/' });
            // }
          }
          break;
        case 403:
          error.message = '拒绝访问';
          break;
        case 404:
          error.message = '请求失败';
          break;
        case 408:
          error.message = '请求超时';
          break;
        case 500:
          error.message = '服务器内部错误';
          break;
        case 501:
          error.message = '服务未实现';
          break;
        case 502:
          error.message = '无法连接服务器';
          break;
        case 503:
          error.message = '服务不可用';
          break;
        case 504:
          error.message = '连接服务器超时';
          break;
        case 505:
          error.message = 'HTTP版本不受支持';
          break;
        default:
      }
    } else {
      // error.message = '无法连接服务器'
    }
    // 对返回的错误处理
    return Promise.reject(error);
  },
);

export async function post(url: string, params: any) {
  try {
    const res = await axios({
      method: 'post',
      url,
      data: params,
    });
    return typeof res !== 'undefined' && res;
  } catch (err: any) {
    return checkCode(err.message);
  }
}

export async function get(url: string, params: any) {
  try {
    const res = await axios.get(url, { params });
    return typeof res !== 'undefined' && res;
  } catch (err: any) {
    return checkCode(err.message);
  }
}

// 下载文件
export async function downloadFile(url: string, params: any) {
  try {
    const res = await axios({
      method: 'post',
      url,
      data: params,
      responseType: 'blob',
    });
    return typeof res !== 'undefined' && res;
  } catch (err: any) {
    return err;
  }
}
