import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
  },
  getters: {
    getLoading(state): boolean {
      return state.loading;
    },
  },
  mutations: {
    setLoading(state, val): void {
      state.loading = val;
    },
  },
  actions: {
  },
  modules: {
  },
});
