import { post } from './request';
/**
 * 温馨家园： wh
 * 之抗战： zkz
 * 康复机构： kf
 * 总预览： kf
 */

// 获取所有监控地址
export const sumEzopenList = (params?: object) => {
  return post('/kf/i/largeSize/sumEzopenList', params);
};
// 获取单个监控视频地址
export const getEzopen = (params: any) => {
  return post('/kf/i/largeSize/getEzopen?whId=' + params.whId, params);
};
/**
 * 总预览
 */

// 获取经纬度 + 描点(总预览)
export const getSiteAll = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/warmListSum', params);
};
// 获取站点总数(总预览)
export const countSumAll = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/countSum', params);
};
// 获取服务类别人数(总预览)
export const typeListSumAll = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/typeListSum', params);
};
// 获取前三十天服务活动人次(总预览 + 温馨家园 + 职康站)
export const serveNumberList = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/serveNumberList', params);
};
// 获取每月签到(总预览)
export const monthSumCountAll = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/monthSumCount', params);
};
// 获取服签到人数(总预览)
export const signSumCountAll = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/numberSignBig', params);
};
// 获取服签到人数
export const signSumCount = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/numberSign', params);
};
// 获取服务人数(总预览)
export const numberSumAll = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/numberDaySumBig', params);
};

/**
 * 温馨家园、之抗战、康复中心数据统计
 */
// 获取经纬度 + 描点(类站点)
export const warmListClass = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/warmList', params);
};
// 获取站点总数(类站点)
export const countClass = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/count', params);
};
// 获取服务类别人数(类站点)
export const typeListClass = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/typeList', params);
};
// 获取每月签到(类站点)
export const monthAllCountClass = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/monthAllCount', params);
};
// 获取签到人数(类站点)
export const signAllCountClass = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/numberSign', params);
};
// 获取服务人数(类站点)
export const numberSumAllClass = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/numberDaySum', params);
};
// 获取通知消息(类站点)
export const adminPageClass = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/adminPage', params);
};
// 获取日报(类站点)
export const toDayPageClass = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/toDayPage', params);
};
// 获取体温异常(类站点)
export const temBigPageClass = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/temBigPage', params);
};

/**
 * 温馨家园、之抗战、康复中心站点详细数据
 */
// 获取站点详细数据
export const showWarmHome = (params: any, path: any) => {
  return post('/' + path + '/i/largeSize/showWarmHome?whId=' + params.whId, params);
};

// 获取经纬度 + 描点(站点)
export const getSiteWh = (params: any, path: any) => {
  return post('/wh/i/largeSize/warmList', params);
};
