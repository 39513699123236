import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'babel-polyfill';
import promise from 'es6-promise';
import './assets/css/public.css';
import VueAMap from 'vue-amap';
// 高德地图
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'a5a48f5b3bfe610f28209c139e422764',
  plugin: [
    // 行政区域查询
    'AMap.DistrictSearch',
    // 多边形绘制
    'AMap.PolyEditor',
    // 图标 + 图标大小
    'AMap.Icon',
    'AMap.Size',
    // 路网图层
    'AMap.TileLayer',
  ],
  v: '1.4.4',
});
promise.polyfill();
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
