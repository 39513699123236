










































import echarts from 'echarts';
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({
  components: {},
})
export default class EchartsA extends Vue {
  @Prop({ type: String }) private readonly id!: string;
  @Prop({ type: String, default: '标题' }) private readonly title!: string;
  @Prop() private readonly textArr!: Array<String>;
  @Prop() private readonly dataArr!: any;
  @Prop() private readonly blockColor!: string;
  @Prop() private readonly listColor!: string[];
  @Prop(Number) public readonly type!: Number;
  public colorArr: string[] = ['#EA3191', '#FF9D4D', '#FAF321', '#47FF64', '#5B8FF9', '#62D9FF', '#B588FF'];
  public blockColorStyle1: string = '';
  public blockColorStyle2: string = '';

  private options: any = {
  tooltip: {
    trigger: 'item'
  },
  series: [
    {
      name: '服务类别',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      data: this.textArr,
      color: this.colorArr,
      tooltip: {
        teigger: 'item',
        formatter: '{a}<br/>{b}残疾 : {c} 人<br/>占比 : {d}%'
      },
    }
  ]

  };
  @Watch('textArr')
  watchTextArr(n: any, o: any) {
    this.options.series[0].data = n
    this.setMap()
  }
  public created() {
    this.blockColorStyle1 = `background-color: ${this.blockColor};`
    this.blockColorStyle2 = `border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) ${this.blockColor};`
  }
  private mounted() {
    this.setMap()
  }
  public setMap() {
    const ele = document.getElementById(this.id);
    const chart: any = echarts.init(ele as HTMLDivElement);
    //调接口获取数据
    chart.setOption(this.options, true);
  }
}
