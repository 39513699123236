










import echarts from 'echarts';
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({
  components: {},
})
export default class Echarts extends Vue {
  @Prop({ type: String }) private readonly id!: string;
  @Prop({ type: String, default: '标题' }) private readonly title!: string;
  @Prop() private readonly textArr!: Array<String>;
  @Prop() private readonly dataArr!: Array<Number>;
  @Prop() private readonly blockColor!: string;
  @Prop() private readonly listColor!: string[];
  public blockColorStyle1: string = '';
  public blockColorStyle2: string = '';

  private options: any = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "0%",
      top: "10px",
      right: "0%",
      bottom: "4%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: this.textArr,
        axisLine: {
          show: true,
          lineStyle: {
            color: "rgba(255,255,255,.1)",
            width: 1,
            type: "solid",
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          interval: 0,
          // rotate:50,
          show: true,
          splitNumber: 15,
          textStyle: {
            color: "#ffffff",
            fontSize: "12",
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          //formatter: '{value} %'
          show: true,
          textStyle: {
            color: "#ffffff",
            fontSize: "12",
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "rgba(255,255,255,.1	)",
            width: 1,
            type: "solid",
          },
        },
        splitLine: {
          lineStyle: {
            color: "rgba(255,255,255,.1)",
          },
        },
      },
    ],
    series: [
      {
        type: "bar",
        data: this.dataArr,
        barWidth: "35%", //柱子宽度
        // barGap: 1, //柱子之间间距
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0,1,0,0,[
              { offset: 0, color: this.listColor[0] },
              { offset: 1, color: this.listColor[1] }
            ]),
            opacity: 1,
            barBorderRadius: [2, 2, 0, 0],
          },
        },
      },
    ],
  };
  @Watch('textArr')
  watchTextArr(n: any, o: any) {
    this.options.xAxis[0].data = n
    this.options.series[0].data = this.dataArr
    this.setMap()
  }
  public created() {
    this.blockColorStyle1 = `background-color: ${this.blockColor};`
    this.blockColorStyle2 = `border-color: rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) ${this.blockColor};`
  }
  private mounted() {
    this.setMap()
  }
  public setMap() {
    const ele = document.getElementById(this.id);
    const chart: any = echarts.init(ele as HTMLDivElement);
    //调接口获取数据
    chart.setOption(this.options, true);
  }
}
