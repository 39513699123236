<template>
  <div id="container" :style="`opacity: 0`"></div>
</template>
<script>
// import { LoginAdminLogout } from '@/api/http'
import { getSiteAll, warmListClass } from '@/http/adminApi';

export default {
  name: 'Mapa',
  components: {},
  data() {
    return {
      map: '', // 地图对象
      inArr: '', // 初始经纬度数组
      markers: [], // 经纬度图标数组
      mapText: '', // 文本标记
      infoWindow: '', // 信息床
      mapOpacity: 1,
      mapZoom: 11.7, // 11.5
      mapZoomFlag: 2,
      mapCenter: [],
      zkzClass: {
        '1': '街道（乡镇）和社区职康劳动项目',
        '2': '民办职康劳动项目',
        '3': '依托社会福利、卫生医疗、教育等机构职康劳动项目'
      }
    }
  },
  props: {
    zoom: {
      type: Number,
      default: 11.7,
    },
    center: {
      type: Array,
      default: [116.510804, 39.945725],
    },
    type: {
      type: Number,
      default: 0
    }
  },
  watch: {
    'type': function(n, o) {
      this.typeClass(n)
    },
    'mapZoom': function(n, o) {
      if (n < 10) {
        if (this.mapZoomFlag !== 1) {
          this.mapZoomFlag = 1
          this.map.remove(this.markers)
          this.markers = []
          this.setMarkers(this.inArr, 9, 12)
        }
      } else if (n >= 10 && n < 12.50) {
        if (this.mapZoomFlag !== 2) {
          this.mapZoomFlag = 2
          this.map.remove(this.markers)
          this.markers = []
          this.setMarkers(this.inArr, 18, 24)
        }
      } else if (n >= 12.50 && n < 15) {
        if (this.mapZoomFlag !== 3) {
          this.mapZoomFlag = 3
          this.map.remove(this.markers)
          this.markers = []
          this.setMarkers(this.inArr, 26, 36)
        }
      } else {
        if (this.mapZoomFlag !== 4) {
          this.mapZoomFlag = 4
          this.map.remove(this.markers)
          this.markers = []
          this.setMarkers(this.inArr, 36, 50)
        }
      }
    }
  },
  created() {
    this.mapCenter = this.$props.center
    this.initialData();
  },
  methods: {
    // 地图回到初始位置和中心点
    toInitial() {
      this.map.setZoom(11.5);
      this.map.setCenter([116.510804, 39.945725])
    },
    // 根据type值 获取对应的站点位置和设置请求路径前缀
    typeClass(type) {
      this.map.remove(this.markers)
      this.markers = []
      if (type == 1) {
        this.sumgetSiteAll()
      } else {
        let str = 'wh'
        if (type == 3) {
          str = 'zkz'
        }
        if (type == 4) {
          str = 'kf'
        }
        this.sumgetSiteClass(str, type)
      }
    },
    // 获取总预览 位置经纬度
    sumgetSiteAll() {
      getSiteAll({}, 'kf').then((res) => {
        this.$store.commit('setLoading', false);
        if (res) {
          const arr = []
          if (res.result.whList) {
            res.result.whList.forEach((element, index) => {
              element.flag = 2
              element.location = this.setLocation(element.location)
              arr.push(element)
            });
          }
          if (res.result.zkzList) {
            res.result.zkzList.forEach((element) => {
              element.flag = 3
              element.location = this.setLocation(element.location)
              arr.push(element)
            });
          }
          if (res.result.kfList) {
            res.result.kfList.forEach((element) => {
              element.flag = 4
              element.location = this.setLocation(element.location)
              arr.push(element)
            });
          }
          this.inArr = arr
          this.setMarkers(arr, 18, 24)
        }
      })
    },

    // 获取温馨家园或职康站或康复中心 各类 位置经纬度
    sumgetSiteClass(str, flag) {
      warmListClass({}, str).then((res) => {
        this.$store.commit('setLoading', false);
        if(res) {
          const arr = []
          if (res.result) {
            res.result.forEach((element) => {
              element.flag = flag
              element.location = this.setLocation(element.location)
              arr.push(element)
            });
          }
          this.inArr = arr
          this.setMarkers(arr, 18, 24)
        }
      })
    },

    // 给地图添加标记点 并且绑定事件
    setMarkers(arr, x, y) {
      const that = this
      const markerTextArr = []
      const markerIconArr = []
      const iconUrl2 = require('../../assets/images/tab1.png')
      const iconUrl3 = require('../../assets/images/tab2.png')
      const iconUrl4 = require('../../assets/images/tab3.png')
      arr.forEach((val, ind) => {
        let iconUrl = iconUrl2
        if(val.flag == 3 || val.flag == '3') {
          iconUrl = iconUrl3
        }
        if(val.flag == 4 || val.flag == '4') {
          iconUrl = iconUrl4
        }
        if (val.location) {
          let obj = {
            name: val.name,
            lnglat: [val.location[1], val.location[0]],
            id: val.id
          }
          markerTextArr.push(obj)
          markerIconArr.push(this.setIcon(iconUrl, [val.location[1], val.location[0]], x, y))
        }
      })
      that.markers = markerIconArr
      that.map.add(markerIconArr)
      markerIconArr.forEach((val, ind) => {
        val.on('mouseover', function() {
          that.setText(markerTextArr[ind].name, markerTextArr[ind].lnglat)
        })
        val.on('mouseout', function () {
          that.map.remove(that.mapText)
          that.mapText = ''
        })
        val.on('click', function () {
          that.srtInfoWindow()
          that.$emit('InfoWindowFun', arr[ind])
        })
      })
    },
    srtInfoWindow(val, location) {
      this.map.remove(this.mapText)
      this.mapText = ''
    },

    // 地图添加文本描述
    setText(name, location) {
      let mapText = new AMap.Text({
        text: name,
        anchor:'center', // 设置文本标记锚点
        draggable:true,
        cursor:'pointer',
        angle:0,
        offset: new AMap.Pixel(0, -45),
        style:{
            'padding': '.04rem .15rem',
            'border-radius': '.1rem',
            'background-color': 'white',
            'width': 'auto',
            'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
            'text-align': 'center',
            'font-size': '.18rem',
        },
        position: location
      });
      this.mapText = mapText
      this.map.add(mapText)
    },

    // 创建一个 Icon
    // iconUrl: 图片路径， location：经纬度数组（[116.500520, 39.955273]）
    setIcon(iconUrl, location, x = 25, y = 25) {
      var startIcon = new AMap.Icon({
        size: new AMap.Size(x, y),
        image: iconUrl,
        imageSize: new AMap.Size(x, y),
      });
      // 将 icon 传入 marker
      var startMarker = new AMap.Marker({
        position: new AMap.LngLat(location[0], location[1]),
        icon: startIcon,
        offset: new AMap.Pixel(-x, -y)
      })
      return startMarker
    },

    // 解析经纬度
    setLocation(e) {
      if (!e) {
        return ''
      }
      const arr =  e.split(',')
      arr[0] = arr[0].split('.')[0] + '.' + arr[0].split('.')[1].substr(0, 6)
      arr[1] = arr[1].split('.')[0] + '.' + arr[1].split('.')[1].substr(0, 6)
      return arr
    },

    // 初始化地图
    initialData() {
      const that = this
      const opts = {
        subdistrict: 0,
        extensions: 'all',
        level: 'city',
      };
      const district = new AMap.DistrictSearch(opts);
      district.search('朝阳区', function (status, result) {
        const bounds = result.districtList[0].boundaries;
        const mask = [];
        bounds.forEach((val, ind) => {
          mask.push([val]);
        });
        const map = new AMap.Map('container', {
          resizeEnable: true,
          viewMode: '3D',
          // center: [116.510804, 39.945725],
          center: that.mapCenter,
          features: ['bg', 'road', 'building'],
          labelzIndex: 130,
          pitch: 20,
          zoom: 11.7,
          mapStyle: 'amap://styles/322f82463fa18269a66e2fcc9a321c14',
          mask,
        });
        // 描边 + 描立体边
        bounds.forEach((val, ind) => {
          new AMap.Polyline({
            map,
            strokeWeight: 2,
            bubble: true,
            zIndex: 100,
            path: val,
            strokeColor: '#4ea8f8',
          });
        });
        const object3Dlayer = new AMap.Object3DLayer({ zIndex: 1 });
        map.add(object3Dlayer);
        const height = -8000;
        const color = '#0088ffcc';
        const wall = new AMap.Object3D.Wall({
          path: bounds,
          height,
          color,
        });
        wall.transparent = true;
        object3Dlayer.add(wall);
        map.on('complete', function () {
          let dom = document.getElementById('container')
          dom.style.removeProperty('background');
          dom.classList.remove("amap-container");
          dom.style.opacity = 1;
        });
        that.map = map
        map.on('zoomchange', function() {
          const zoom = map.getZoom(); //获取当前地图级别
          that.mapZoom = zoom
        });
        that.typeClass(that.$props.type)
      });
    },
  }
};
</script>
<style lang="less" scoped>
#container{
  position: relative;
  touch-action: none;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 10;
}
/deep/.amap-container{
  background: none;
}
</style>
